import { addBooking, getExpertBookingOptions } from '@api/experts';
import AudioIcon from '@assets/images/icons/audio-only.svg';
import CalendarIcon from '@assets/images/icons/calendar-check-icon.svg';
import CheckedTick from '@assets/images/icons/checked-tick.svg';
import ClockIcon from '@assets/images/icons/clock-icon.svg';
import InstantMessagingIcon from '@assets/images/icons/instant-messaging.svg';
import DefaultIcon from '@assets/images/icons/phone-call-dark.svg';
import RotateIcon from '@assets/images/icons/rotate-icon.svg';
import UserIcon from '@assets/images/icons/user-icon.svg';
import VideoIcon from '@assets/images/icons/video-only.svg';
import WarningIcon from '@assets/images/icons/warning-icon.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { smoothScrollTo } from '@helpers/general.helpers';
import useWindowDimensions from '@hooks/useWindowDimensions';
import {
  ExpertBooking,
  ExpertBookingOptionsData,
  ExpertInterface,
  ExpertSessionDurationOptionsInterface,
  ExpertSessionTypeOptionsInterface,
} from '@interfaces/index';
import { CircularProgress, Skeleton } from '@mui/material';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { castResponse } from 'custom.d';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

const ConfirmBooking: React.FunctionComponent<ConfirmBookingProps> = ({
  handleBookingEnabling,
  bookingData,
  timezoneDetails,
  expertDetails,
  handleFetchAvailability,
}) => {
  const { isDesktop } = useWindowDimensions();
  const [isBookingSuccess, setIsBookingSuccess] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [continueBooking, setContinueBooking] = useState(false);
  const [expertData, setExpertData] = useState<ExpertBookingOptionsData | null>(null);
  const [selectedSessionDuration, setSelectedSessionDuration] =
    useState<ExpertSessionDurationOptionsInterface | null>(null);
  const [sessionLoading, setSessionLoading] = useState(true);
  const [selectedSessionType, setSelectedSessionType] =
    useState<ExpertSessionTypeOptionsInterface | null>(null);
  const [sessionDetails, setSessionDetails] = useState<ExpertBooking | null>(null);
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const [lastSessionDurationErrorMsgEnabled, setLastSessionDurationErrorMsgEnabled] =
    useState(false);
  const SESSION_DURATION_INITIAL_VALUE = '25';

  useEffect(() => {
    if (expertDetails?.id) {
      getOptions();
    }
  }, [expertDetails.id]);

  const getOptions = async () => {
    setSessionLoading(true);
    const response = await getExpertBookingOptions(expertDetails.id);
    if (response?.success) {
      const data = castResponse<ExpertBookingOptionsData>(response);
      if (
        data?.expertSessionDurations?.length > 0 &&
        data?.expertSessionDurations?.filter(
          (duration: { sessionDurationName: string }) =>
            duration?.sessionDurationName !== SESSION_DURATION_INITIAL_VALUE,
        )
      ) {
        setLastSessionDurationErrorMsgEnabled(true);
      }

      data.expertSessionDurations = data.expertSessionDurations
        .filter((duration: { sessionDurationName: string }) => {
          if (bookingData.timeDifference === 0) {
            return duration.sessionDurationName === SESSION_DURATION_INITIAL_VALUE;
          }

          return Number(duration.sessionDurationName) <= bookingData.timeDifference;
        })
        .sort(
          (a: { sessionDurationName: string }, b: { sessionDurationName: string }) =>
            Number(a.sessionDurationName) - Number(b.sessionDurationName),
        );

      data.expertSessionTypes = data.expertSessionTypes.sort(
        (a: { sessionType: any }, b: { sessionType: any }) =>
          Number(a.sessionType) - Number(b.sessionType),
      );

      setSelectedSessionDuration(
        data?.expertSessionDurations?.find((dt: { default: boolean }) => dt.default) ||
          data?.expertSessionDurations[0],
      );
      setSelectedSessionType(data.expertSessionTypes[0]);
      setExpertData(data);
      setSessionLoading(false);
    } else {
      setSelectedSessionDuration(null);
      setSelectedSessionType(null);
      setExpertData(null);
      setSessionLoading(false);
    }
  };

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);

    const options: any = { weekday: 'short', day: 'numeric', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
  };

  function convertToUTC(
    dateObj: { date: string | null; time: string | null },
    timezoneOffset: string,
  ) {
    const { date, time } = dateObj;

    if (!date || !time) {
      throw new Error('Date and time must be provided');
    }

    const localDateTimeString = `${date}T${time}:00`;

    const localDateTime = moment(localDateTimeString).utcOffset(timezoneOffset, true);

    const utcDateTime = localDateTime.utc();

    return utcDateTime.format('YYYY-MM-DDTHH:mm:ss');
  }

  const confirmBooking = async () => {
    try {
      if (selectedSessionType && selectedSessionDuration) {
        setBookingLoading(true);
        const req: any = await addBooking(
          convertToUTC(bookingData, timezoneDetails?.offSet),
          expertDetails.id,
          selectedSessionType.sessionType,
          selectedSessionDuration?.sessionDuration,
        );

        if (req?.success) {
          const data = castResponse<ExpertBooking>(req);
          await setSessionDetails(data);
          await setIsBookingSuccess(true);
          await toast.success('Booking successful. Please check your email for more information.');
        } else {
          toast.error(req?.responseObject[0]?.message || 'Booking failed. Please try again.');
        }
      } else {
        setIsBookingSuccess(false);
        toast.error('Error occurred. Try again later or contact admin.');
      }
    } catch (e) {
      setIsBookingSuccess(false);
      toast.error('Error occurred. Try again later or contact admin.');
    } finally {
      setBookingLoading(false);
    }
  };

  const handleSelectSessionDuration = (sessionDue: ExpertSessionDurationOptionsInterface) => {
    setSelectedSessionDuration(sessionDue);
  };

  const handleSelectSessionType = (sessionDue: ExpertSessionTypeOptionsInterface) => {
    setSelectedSessionType(sessionDue);
  };

  const getIconForType = (typeName: string | undefined | null) => {
    switch (typeName?.toLowerCase()) {
      case 'video':
        return VideoIcon;
      case 'audio':
        return AudioIcon;
      case 'instant messaging':
      case 'im':
        return InstantMessagingIcon;
      default:
        return DefaultIcon;
    }
  };

  const addMinutes = (date: string | null, time: string | null, minutesToAdd: number): string => {
    if (!date || !time) {
      return '00:00';
    }

    return new Date(new Date(`${date}T${time}:00Z`).getTime() + minutesToAdd * 60000)
      ?.toISOString()
      ?.substr(11, 5);
  };

  const newEndTime =
    bookingData?.date && bookingData?.time && selectedSessionDuration?.sessionDurationName
      ? addMinutes(
          bookingData?.date,
          bookingData?.time,
          Number(selectedSessionDuration?.sessionDurationName),
        )
      : '00:00';

  return (
    <>
      {expertData?.expertSessionTypes?.length === 0 ||
      expertData?.expertSessionDurations?.length === 0 ? (
        <>
          {' '}
          <div className={styles.bookingUnavailableSection}>
            <p>Select your desired session date and time to book this expert.</p>
            <div className={styles.bookingUnavailableSectionContent}>
              <Img src={WarningIcon} alt="Warning icon" className={styles.warningIcon} />
              <div className={styles.content}>
                {lastSessionDurationErrorMsgEnabled ? (
                  <>
                    <p>This time slot is not available for the session duration.</p>
                    <p>Please select a different time slot. </p>
                  </>
                ) : (
                  <>
                    <p>This expert is currently not available.</p>
                    <p>Please try later or select a different expert.</p>
                  </>
                )}

                <DefaultButton
                  variant="secondary"
                  onClick={() => {
                    handleBookingEnabling({
                      enabled: false,
                      date: null,
                      time: null,
                      timeDifference: 0,
                    });
                    setLastSessionDurationErrorMsgEnabled(false);
                  }}
                >
                  Back
                </DefaultButton>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.bookingContainer}>
          <div className={styles.card}>
            {!continueBooking ? (
              <>
                <h5>Your booking</h5>

                {isBookingSuccess && (
                  <div className={styles.checkBoxWrapper}>
                    {' '}
                    <svg
                      className={styles.checkmark}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      {' '}
                      <circle
                        className={styles.checkmarkCircle}
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />{' '}
                      <path
                        className={styles.checkmarkCheck}
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                )}

                <div className={styles.bookingInfoSection}>
                  <div className={styles.bookingInfo}>
                    <Img src={UserIcon} alt="user-icon" className={styles.icon} />
                    <p>{`${expertDetails.firstName} ${expertDetails.lastName}`}</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className={styles.bookingInfo}>
                    <Img src={CalendarIcon} alt="calendar-icon" className={styles.icon} />
                    <p>{bookingData.date && formatDate(bookingData.date)}</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className={styles.bookingInfo}>
                    <Img src={ClockIcon} alt="clock-icon" className={styles.icon} />
                    <p>{`${bookingData.time} (${timezoneDetails.timeZone})`}</p>
                    <p>&nbsp;</p>
                  </div>
                </div>

                <div className={styles.sessionDurationsSelection}>
                  <h5 className={styles.sessionDurationTopic}>Select session duration</h5>
                  <div className={styles.bookingSessionDurations}>
                    {sessionLoading ? (
                      <>
                        <Skeleton width="100px" height="100px" variant="rounded" />
                        <Skeleton width="100px" height="100px" variant="rounded" />
                        <Skeleton width="100px" height="100px" variant="rounded" />
                      </>
                    ) : (
                      <>
                        {expertData &&
                          expertData?.expertSessionDurations.map((sessionDue, index) => (
                            <div
                              className={`${styles.bookingSessionDuration} ${
                                selectedSessionDuration &&
                                selectedSessionDuration.sessionDuration ===
                                  sessionDue.sessionDuration
                                  ? styles.selected
                                  : ''
                              } `}
                              key={index}
                              onClick={() => handleSelectSessionDuration(sessionDue)}
                            >
                              <div>
                                <p>{sessionDue.sessionDurationName}</p>
                                <p>minutes</p>
                              </div>

                              {selectedSessionDuration?.sessionDuration ===
                              sessionDue.sessionDuration ? (
                                <Img
                                  src={CheckedTick}
                                  alt={`${sessionDue?.sessionDurationName} checked`}
                                  className={styles.checkTick}
                                />
                              ) : (
                                <div className={styles.checkTickNullImage} />
                              )}
                            </div>
                          ))}
                      </>
                    )}
                  </div>

                  <div className={styles.bookingSessionTypesInfo}>
                    {sessionLoading ? (
                      <div className={styles.sessionLoading}>
                        <Skeleton width="100%" height="20px" variant="text" />
                        <Skeleton width="100%" height="20px" variant="text" />
                      </div>
                    ) : (
                      <>
                        {selectedSessionDuration?.sessionDurationName ===
                        SESSION_DURATION_INITIAL_VALUE ? (
                          <>
                            <p>
                              {selectedSessionDuration?.sessionDurationName} minutes session is
                              recommended for initial consultations and check-ups.
                            </p>
                            <p className={styles.creditsInfo}>
                              This will consume {selectedSessionDuration?.sessionDurationCost} of
                              your remaining {expertData?.usersRemainingCredits} credits.
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              {selectedSessionDuration?.sessionDurationName || 0} minutes session is
                              recommended for a more in-depth session.
                            </p>
                            <p className={styles.creditsInfo}>
                              This will consume {selectedSessionDuration?.sessionDurationCost || 0}{' '}
                              of your remaining {expertData?.usersRemainingCredits || 0} credits.
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className={styles.sessionTypesSection}>
                  <h5 className={styles.sessionTypeTopic}>Select session type</h5>

                  <div className={styles.bookingSessionTypes}>
                    {sessionLoading ? (
                      <>
                        <Skeleton width="100px" height="100px" variant="rounded" />
                        <Skeleton width="100px" height="100px" variant="rounded" />
                        <Skeleton width="100px" height="100px" variant="rounded" />
                      </>
                    ) : (
                      <>
                        {expertData?.expertSessionTypes.map((sessionType, index) => (
                          <div
                            className={`${styles.bookingSessionType} ${
                              selectedSessionType?.sessionType === sessionType.sessionType
                                ? styles.selected
                                : ''
                            } `}
                            key={index}
                            onClick={() => handleSelectSessionType(sessionType)}
                          >
                            <div className={styles.typeIconWrapper}>
                              <Img
                                className={styles.typeIcon}
                                src={getIconForType(sessionType.sessionTypeName)}
                                alt={sessionType.sessionTypeName}
                              />
                            </div>

                            <p>{sessionType.sessionTypeName}</p>

                            {selectedSessionType &&
                            selectedSessionType.sessionType === sessionType.sessionType ? (
                              <Img
                                src={CheckedTick}
                                alt={`${sessionType.sessionTypeName} checked`}
                                className={styles.checkTick}
                              />
                            ) : (
                              <div className={styles.checkTickNullImage} />
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.btnContainer}>
                  {isBookingSuccess ? (
                    <div className={styles.btns}>
                      <DefaultButton
                        variant="secondary"
                        onClick={() => {
                          handleBookingEnabling({
                            enabled: false,
                            date: null,
                            time: null,
                            timeDifference: 0,
                          });
                          setLastSessionDurationErrorMsgEnabled(false);
                          smoothScrollTo(0, 150);
                        }}
                      >
                        Go back
                      </DefaultButton>{' '}
                    </div>
                  ) : (
                    <div className={styles.btns}>
                      <DefaultButton
                        onClick={() => {
                          if (isDesktop) {
                            smoothScrollTo(1200, 150);
                          } else {
                            smoothScrollTo(2000, 150);
                          }

                          setContinueBooking(true);
                        }}
                        disabled={
                          expertData?.expertSessionTypes?.length === 0 ||
                          expertData?.expertSessionDurations?.length === 0 ||
                          selectedSessionDuration === null ||
                          selectedSessionType === null
                        }
                      >
                        Continue
                      </DefaultButton>

                      <DefaultButton
                        variant="secondary"
                        onClick={() => {
                          handleBookingEnabling({
                            enabled: false,
                            date: null,
                            time: null,
                            timeDifference: 0,
                          });
                          setLastSessionDurationErrorMsgEnabled(false);
                        }}
                      >
                        Cancel
                      </DefaultButton>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <h5>{isBookingSuccess ? 'Your booking is completed' : 'Confirm your booking'}</h5>

                {isBookingSuccess && (
                  <div className={styles.checkBoxWrapper}>
                    {' '}
                    <svg
                      className={styles.checkmark}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      {' '}
                      <circle
                        className={styles.checkmarkCircle}
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />{' '}
                      <path
                        className={styles.checkmarkCheck}
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                )}

                <div className={styles.bookingInfoSection}>
                  <div className={styles.bookingInfo}>
                    <Img src={UserIcon} alt="user-icon" className={styles.icon} />
                    <p>{`${expertDetails.firstName} ${expertDetails.lastName}`}</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className={styles.bookingInfo}>
                    <Img src={CalendarIcon} alt="calendar-icon" className={styles.icon} />
                    <p>{bookingData.date && formatDate(bookingData.date)}</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className={styles.bookingInfo}>
                    <Img src={ClockIcon} alt="clock-icon" className={styles.icon} />
                    <p>{`${bookingData.time} (${timezoneDetails.timeZone})`}</p>
                    <p>&nbsp;</p>
                  </div>

                  <div className={styles.bookingInfo}>
                    <Img src={RotateIcon} alt="rotate-icon" className={styles.icon} />
                    <p>
                      {selectedSessionDuration && selectedSessionDuration?.sessionDurationName}{' '}
                      Minutes
                    </p>
                    <p>&nbsp;</p>
                  </div>

                  {selectedSessionType && (
                    <div className={styles.bookingInfo}>
                      <Img
                        src={getIconForType(selectedSessionType?.sessionTypeName)}
                        alt="rotate-icon"
                        className={styles.icon}
                      />
                      <p>{selectedSessionType?.sessionTypeName || '-'}</p>
                      <p>&nbsp;</p>
                    </div>
                  )}
                </div>

                {isBookingSuccess && (
                  <p className={styles.successMessage}>
                    You will be able to join your session via the link on your Hapstar dashboard or
                    Experts sections. You will also receive an email with the link to your session.
                    If you don’t receive the email, please ensure to check your spam/junk folder.
                  </p>
                )}

                <div className={styles.btnContainer}>
                  {isBookingSuccess ? (
                    <div className={styles.btnAddToCalendar}>
                      <AddToCalendarButton
                        name={`[Reminder] Meeting with Expert - ${expertDetails?.firstName} ${expertDetails?.lastName}`}
                        options={[
                          'Microsoft365',
                          'Google',
                          'Outlook.com',
                          'MicrosoftTeams',
                          'Yahoo',
                          'iCal',
                          'Apple',
                        ]}
                        description={`You have a scheduled session with ${
                          expertDetails?.firstName
                        } ${expertDetails?.lastName}.
                 Session Details:
                     - Duration: ${selectedSessionDuration?.sessionDurationName} minutes
                     - Type: ${selectedSessionType?.sessionTypeName}
                     - Session URL: ${sessionDetails?.sessionUrl || ''}
                     [br]
                     → [url]${sessionDetails?.sessionUrl || ''}|Join now[/url]
                     [br]
                 Please join the session using the provided URL at the scheduled time.
                   `}
                        location="Online"
                        startDate={bookingData.date?.toString()}
                        endDate={bookingData.date?.toString()}
                        startTime={bookingData.time?.toString()}
                        endTime={newEndTime.toString()}
                        timeZone={timezoneDetails?.timeZone || 'currentBrowser'}
                        organizer="Hapstar|hello@hapstar.app"
                        attendee={user?.email}
                        hideBranding
                        buttonStyle="round"
                        hideCheckmark
                        label="Add to your calendar"
                        styleLight="--btn-background: #019df2; --btn-text: #fff; --font: 'Titillium Web', sans-serif;"
                      />
                    </div>
                  ) : (
                    <>
                      <p className={styles.disclaimerText}>
                        By clicking &quot;Complete Booking&quot;, you agree to have your contact
                        details shared with our partner, MindBerry, who provides the platform to
                        facilitate your 1-to-1 session with the expert.{' '}
                      </p>
                      <div className={styles.btns}>
                        <DefaultButton
                          onClick={confirmBooking}
                          disabled={
                            expertData?.expertSessionTypes?.length === 0 ||
                            expertData?.expertSessionDurations?.length === 0 ||
                            selectedSessionDuration === null ||
                            selectedSessionType === null ||
                            bookingLoading
                          }
                        >
                          {bookingLoading ? (
                            <>
                              {' '}
                              <CircularProgress
                                color="inherit"
                                size={20}
                                className={styles.loadingSpinner}
                              />
                              &nbsp;&nbsp;&nbsp;Connecting...
                            </>
                          ) : (
                            'Complete Booking'
                          )}
                        </DefaultButton>

                        <DefaultButton
                          variant="secondary"
                          onClick={() => setContinueBooking(false)}
                          disabled={bookingLoading}
                        >
                          Back
                        </DefaultButton>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

interface ConfirmBookingProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  handleBookingEnabling: (arg0: {
    enabled: boolean;
    date: string | null;
    time: string | null;
    timeDifference: number;
  }) => void;
  bookingData: {
    enabled: boolean;
    date: string | null;
    time: string | null;
    timeDifference: number;
  };
  timezoneDetails: {
    id: number;
    name: string;
    timeZone: string;
    offSet: string;
  };
  expertDetails: ExpertInterface;
  handleFetchAvailability: () => void;
}

export default ConfirmBooking;
