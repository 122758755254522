import {
  fetchAllExpertsToDB,
  getAllExperts,
  getExpertAssignedQuestions,
  updateAllExpertsDetails,
  updateExpertQuestionData,
} from '@api/experts';
import { getShortStarQuestionsData } from '@api/shortStar';
import AutocompleteComponent from '@components/Atoms/Autocomplete';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import Modal from '@components/Atoms/Modal';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ExpertsResultsResponse } from '@interfaces/index';
import MainLayout from '@layouts/MainLayout';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import GetAppIcon from '@mui/icons-material/GetApp';
import UpdateIcon from '@mui/icons-material/Update';
import {
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import classNames from 'classnames';
import { USER_NOT_FOUND_IMAGE, castResponse } from 'custom.d';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExpertsManagement: FunctionComponent = () => {
  const { isDesktop, isTablet } = useWindowDimensions();
  const [experts, setExperts] = useState<
    | {
        label: string;
        value: number;
        imageUrl?: string;
      }[]
    | []
  >([]);
  const [selectedExpertOpt, setSelectedExpertOpt] = useState<{
    label: string;
    value: number;
    imageUrl?: string;
  } | null>(null);
  const [starQuestionsDataLoading, setStarQuestionsDataLoading] = useState(true);
  const [starQuestions, setStarQuestions] = useState<
    | {
        label: string;
        value: number;
        imageUrl?: string;
      }[]
    | []
  >([]);
  const [selectedQuestionOpt, setSelectedQuestionOpt] = useState<
    | {
        label: string;
        value: number;
        imageUrl?: string;
      }[]
    | []
  >([]);

  const [fetchExpertsOpen, setFetchExpertsOpen] = useState<boolean>(false);

  const [loadingAllExpertsFetch, setLoadingAllExpertsFetch] = useState<boolean>(false);
  const [allExpertsFetchRes, setAllExpertsFetchRes] = useState<any>(null);
  const [loadingUpdateExpertsFetch, setLoadingUpdateExpertsFetch] = useState<boolean>(false);
  const [updatedExpertsFetchRes, setUpdatedExpertsFetchRes] = useState<any>(null);
  const [assignedQueLoading, setAssignedQueLoading] = useState<boolean>(false);
  const [errorUpdating, setErrorUpdating] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    getExperts();
    getStarQuestions();
  }, []);

  const getExperts = async () => {
    try {
      const response = await getAllExperts(null, null, 0, 999);

      if (response?.success) {
        const data = castResponse<ExpertsResultsResponse>(response);
        const modRes = data?.results.map(
          (item: {
            firstName: string;
            lastName: string;
            id: any;
            expertDetail: { profileImage: string };
          }) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
            imageUrl: item?.expertDetail?.profileImage
              ? `https://${item.expertDetail.profileImage}`
              : USER_NOT_FOUND_IMAGE,
          }),
        );

        setExperts(modRes);
      } else {
        setExperts([]);
      }
    } catch {
      setExperts([]);
    }
  };

  const getStarQuestions = async () => {
    try {
      setStarQuestionsDataLoading(true);
      const response = await getShortStarQuestionsData();
      const updatedResponse = response.map((dt) => ({
        label: dt.questionText,
        value: dt.questionId,
        icon: `../../../../../images/shortStar/${dt.icon}.svg`,
      }));
      setStarQuestions(updatedResponse);
    } catch (error) {
      setStarQuestions([]);
    } finally {
      setStarQuestionsDataLoading(false);
    }
  };

  const fetchAllExperts = async () => {
    try {
      setLoadingAllExpertsFetch(true);
      const response = await fetchAllExpertsToDB();

      setAllExpertsFetchRes(response);
    } catch (error) {
      setAllExpertsFetchRes(null);
    } finally {
      setLoadingAllExpertsFetch(false);
    }
  };

  const updateAllExperts = async () => {
    try {
      setLoadingUpdateExpertsFetch(true);
      const response: any = await updateAllExpertsDetails();

      if (response?.success) {
        const data = castResponse<{ updatedExpertDetails: number }>(response);
        toast.success('Experts details updated successfully');
        setErrorUpdating(false);
        setUpdatedExpertsFetchRes(data);
      } else {
        setErrorMessage(
          response?.responseObject[0]?.message ||
            'Error occurred try again later or check database.',
        );
        toast.error(response?.responseObject[0]?.message || 'Error updating experts details');
        setErrorUpdating(true);
      }
    } catch (error) {
      setErrorUpdating(true);
      setUpdatedExpertsFetchRes(null);
    } finally {
      setLoadingUpdateExpertsFetch(false);
    }
  };

  useEffect(() => {
    if (selectedExpertOpt?.value && selectedExpertOpt?.value > 0) {
      getAssignedQuestions(selectedExpertOpt?.value);
    }
  }, [selectedExpertOpt]);

  const getAssignedQuestions = async (expertId: number) => {
    try {
      setAssignedQueLoading(true);
      const response = await getExpertAssignedQuestions(expertId);

      const updatedResponse = response.map(
        (dt: { question: { questionText: string; icon: string }; questionId: number }) => ({
          label: dt.question.questionText,
          value: dt.questionId,
          icon: `../../../../../images/shortStar/${dt.question.icon}.svg`,
        }),
      );

      setSelectedQuestionOpt(updatedResponse);
    } catch (error) {
      setSelectedQuestionOpt([]);
    } finally {
      setAssignedQueLoading(false);
    }
  };

  const assignShortQuestions = async (expertId: number, questionIds: number[]) => {
    try {
      const response: any = await updateExpertQuestionData(expertId, questionIds);
      if (response?.success) {
        toast.success('Short Questions Assigned Successfully');
      } else {
        toast.error(response?.responseObject[0]?.message || 'Error occurred. Try again later');
      }
    } catch (error: any) {
      toast.error(error[0]?.message || 'Error occurred. Try again later');
    }
  };

  const synchronizedSelectedQueOptions = useMemo(
    () =>
      selectedQuestionOpt.map(
        (selectedItem) =>
          starQuestions.find((option) => option.value === selectedItem.value) || selectedItem,
      ),
    [selectedQuestionOpt, starQuestions],
  );

  const handleAutocompleteChange = (event: any, value: any) => {
    const uniqueValue = Array.from(new Set(value.map((v: any) => v.value))).map((valueId) =>
      value.find((v: any) => v.value === valueId),
    );

    setSelectedQuestionOpt(uniqueValue);
  };

  return (
    <MainLayout title="Experts Management" useSuperAdminNav isSuperAdminPage secondaryNavEnabled>
      <div className={styles.mainContainer}>
        {fetchExpertsOpen && (
          <div className={styles.overlay}>
            <Modal
              open={fetchExpertsOpen}
              setOpen={setFetchExpertsOpen}
              className={styles.modalRoot}
            >
              <div>
                <DefaultButton
                  color="primary"
                  type="button"
                  className={styles.btnClose}
                  onClick={() => {
                    setFetchExpertsOpen(false);
                    setUpdatedExpertsFetchRes(null);
                    setAllExpertsFetchRes(null);
                    setLoadingAllExpertsFetch(false);
                    setLoadingUpdateExpertsFetch(false);
                    setErrorUpdating(false);
                    setErrorMessage('');
                  }}
                >
                  <div className={styles.btnCloseLabel}>Close</div>
                  <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
                </DefaultButton>
                <h2 className={styles.modalTitle}>Fetch experts details</h2>

                <div className={styles.expertsFetchContainer}>
                  <h5>Step 1</h5>
                  <div className={styles.expertsFetchContainerInner}>
                    <p>Fetch experts to the database</p>
                    <DefaultButton
                      onClick={fetchAllExperts}
                      disabled={loadingAllExpertsFetch || allExpertsFetchRes}
                    >
                      {loadingAllExpertsFetch ? (
                        <>
                          <CircularProgress
                            color="inherit"
                            size={20}
                            className={styles.loadingSpinner}
                          />
                          &nbsp;&nbsp;&nbsp;Loading
                        </>
                      ) : (
                        <>
                          <GetAppIcon />
                          &nbsp;&nbsp;&nbsp;Fetch experts
                        </>
                      )}
                    </DefaultButton>
                  </div>

                  <hr />

                  {allExpertsFetchRes && (
                    <TableContainer component={Paper} className={styles.tableContainer}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <span>Available experts</span>
                            </TableCell>
                            <TableCell align="right">
                              <span>Added experts</span>
                            </TableCell>
                            <TableCell align="right">
                              <span>Removed experts</span>
                            </TableCell>
                            <TableCell align="right">
                              <span>Updated experts</span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              {allExpertsFetchRes?.allExpertsAvailableInDB || 0}
                            </TableCell>
                            <TableCell align="right">
                              {allExpertsFetchRes?.expertsAdded || 0}
                            </TableCell>
                            <TableCell align="right">
                              {allExpertsFetchRes?.expertsRemoved || 0}
                            </TableCell>
                            <TableCell align="right">
                              {allExpertsFetchRes?.expertsUpdated || 0}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>

                <div className={styles.expertsFetchContainer}>
                  <h5>Step 2</h5>
                  <div className={styles.expertsFetchContainerInner}>
                    <p>Fetch and update experts details to the database</p>
                    <DefaultButton
                      onClick={updateAllExperts}
                      disabled={
                        loadingUpdateExpertsFetch || !allExpertsFetchRes || loadingAllExpertsFetch
                      }
                    >
                      {loadingUpdateExpertsFetch ? (
                        <>
                          <CircularProgress
                            color="inherit"
                            size={20}
                            className={styles.loadingSpinner}
                          />
                          &nbsp;&nbsp;&nbsp;Loading
                        </>
                      ) : (
                        <>
                          <UpdateIcon />
                          &nbsp;&nbsp;&nbsp;Update experts details
                        </>
                      )}
                    </DefaultButton>
                  </div>
                  {errorUpdating && !loadingUpdateExpertsFetch ? (
                    <div className={styles.errorUpdating}>
                      <p>
                        {errorMessage?.length > 0
                          ? errorMessage
                          : 'Error occurred try again later or check database.'}
                      </p>
                    </div>
                  ) : (
                    <>
                      {updatedExpertsFetchRes && !errorUpdating && !loadingUpdateExpertsFetch && (
                        <h6 className={styles.updatedExpertsFetchRes}>
                          Updated experts count: {updatedExpertsFetchRes?.updatedExpertDetails || 0}
                        </h6>
                      )}

                      {updatedExpertsFetchRes &&
                        allExpertsFetchRes &&
                        !errorUpdating &&
                        !loadingUpdateExpertsFetch && (
                          <>
                            <hr />
                            <span>
                              Fetching experts to the database completed. You can close the popup
                              and continue.
                            </span>
                          </>
                        )}
                    </>
                  )}
                </div>
              </div>
            </Modal>
          </div>
        )}
        <div className={styles.topSection}>
          <h2 className={styles.mainTitle}>Experts Management</h2>
          <div className={styles.topSectionButtons}>
            <DefaultButton variant="primary" onClick={() => setFetchExpertsOpen(true)}>
              Fetch experts
            </DefaultButton>
          </div>
        </div>

        <div className={styles.selectionContainer}>
          <AutocompleteComponent
            options={experts}
            label="Select expert"
            isError={false}
            onChange={(event: any, value: any) => setSelectedExpertOpt(value)}
            getOptionLabel={(option: { label: string }) => option.label}
            renderOption={(
              props: JSX.IntrinsicAttributes &
                React.ClassAttributes<HTMLLIElement> &
                React.LiHTMLAttributes<HTMLLIElement>,
              option: {
                value: number;
                imageUrl: string;
                label: string;
              },
            ) => (
              <li {...props}>
                {option.imageUrl ? (
                  <Img
                    src={option.imageUrl}
                    alt={option.label}
                    className={styles.autocompleteImageOption}
                  />
                ) : (
                  <div className={styles.autocompleteImageOption} />
                )}
                <div className={styles.contentAutocompleteOption}>
                  <span>
                    #{option.value} <h6>{option.label}</h6>
                  </span>
                </div>
              </li>
            )}
          />

          {!selectedExpertOpt ? (
            <h6>Please select expert to continue.</h6>
          ) : (
            <div>
              <Card className={styles.cardContainer}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <h5>Short Questions</h5>

                    <AutocompleteComponent
                      options={starQuestions}
                      label="Select question/s"
                      isError={false}
                      value={synchronizedSelectedQueOptions}
                      onChange={(event: any, value: any) => {
                        handleAutocompleteChange(event, value);
                      }}
                      renderTags={(value: any, getTagProps: any) =>
                        value.map((option: { label: string }, index: number) => (
                          <Chip
                            key={index}
                            variant="filled"
                            label={option.label}
                            size="small"
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(
                        props: JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLLIElement> &
                          React.LiHTMLAttributes<HTMLLIElement>,
                        option: {
                          label: string;
                        },
                        { selected }: any,
                      ) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInputProps={{
                        placeholder: 'Short Questions',
                      }}
                      multiple
                    />
                  </Grid>
                </Grid>

                <div className={styles.saveButton}>
                  <DefaultButton
                    disabled={assignedQueLoading || starQuestionsDataLoading}
                    onClick={() => {
                      if (selectedExpertOpt.value) {
                        assignShortQuestions(
                          selectedExpertOpt.value,
                          selectedQuestionOpt.map((item) => item.value),
                        );
                      }
                    }}
                  >
                    Save
                  </DefaultButton>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ExpertsManagement;
